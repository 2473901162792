import React, { useState, useEffect } from "react";
import web3Utils from '../../Utils/web-utils'
import "../../styles/BuyOffers.css";
import { Puff } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom"
import swal from 'sweetalert'
import binance from 'binance-api-react-native'
import axios from 'axios'
import { RefreshCw } from "react-feather";
import coins from "../../assets/monedas/index"
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const BuyOffers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const type = "1"

  const [coin, setCoin] = useState(2)
  const [coinSelected, setCoinSelected] = useState(null)
  const [tokenSwap, setTokenSwap] = useState(4)
  const [tokenSwapSelected, setTokenSwapSelected] = useState(null)
  const [tokenNamesById, setTokenNamesById] = useState([])
  const [tokenAddressById, setTokenAddressById] = useState([])
  const [tokenToName, setTokenToName] = useState("SPS")
  const [tokenSwapName, setTokenSwapName] = useState("USDT")
  const [isLoading, setLoading] = useState(false)
  const [weiAmmount, setWeiAmmount] = useState(1)
  const [totalCantPrice, setTotalCantPrice] = useState()
  const [totalFee, setTotalFee] = useState()
  const [escrowAgentAddress, setEscrowAgentAddress] = useState((web3Utils.getUserSesionData().provider === 3)
    ? "41b13c8e766329c4ee09a81056889c9b0e58db33f0" : "0x47b833781a04AB68777f0C3881009b0103e3666A")
  const [copPrice, setCopPrice] = useState(0)
  const [copPriceFixed, setCopPriceFixed] = useState("0")
  const [copPricePorce, setCopPricePorce] = useState(4)
  const [bank, setBank] = useState("")
  const [tokenList, setTokenList] = useState([{}])
  const [scrowerList, setScrowerList] = useState([])
  const [bankList, setBankList] = useState([])
  const [chaturbate, setChaturbate] = useState("")
  const [chaturbateList, setChaturbateList] = useState([])
  const tokens = {
    FiatUsd: 0,
    FiatCop: 1,
    USDT: 2,
    BUSD: 3,
    SparkLifeToken: 4,
    BancannabisToken: 5,
    BtcBinance: 6,
    BtcsSpark: 7
  }

  var newEscrow = async (e) => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);

    let weiAmmountSwap = weiAmmount * copPrice
    let spsAmmount, priceWei

    if (web3Utils.getUserSesionData().provider !== 3) {
      spsAmmount = web3Utils.toWei(weiAmmount.toString(), 'ether')
      priceWei = web3Utils.toWei(copPrice.toString(), 'ether')
      weiAmmountSwap = web3Utils.toWei(Number(weiAmmountSwap).toFixed(18).replace(/\.?0+$/, ""), 'ether')
      if (coin === 4) { // si es sps, restamos decimales (10 decimales)
        spsAmmount = spsAmmount.substr(0, spsAmmount.length - 10)
      }
      //spsAmmount = [web3Utils.toWei(weiAmmount, 'mwei'), "00"].join("")
    }
    else {

      let decimalsTokenTo = web3Utils.getDecimalsByTokenId(coin)
      let decimalsTokenSwap = web3Utils.getDecimalsByTokenId(tokenSwap)

      spsAmmount = weiAmmount * 10 ** decimalsTokenTo
      priceWei = web3Utils.toBN(copPrice * 10 ** 18).toString(10)
      weiAmmountSwap = web3Utils.toBN(weiAmmountSwap * 10 ** 18).toString(10)
      if (decimalsTokenSwap < 18)
        weiAmmountSwap = weiAmmountSwap.substr(0, decimalsTokenSwap)
    }

    let bytes = web3Utils.fromAscii(bank)
    if (bytes.length < 66)
      bytes = bytes + web3Utils.completeWithZero(bytes.length, 66)
    if (bytes === "") bytes = "0x0000000000000000000000000000000000000000000000000000000000000000"
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      // TRC Network
      txNetwork = p2pContract.methods
        .newEscrowSellOrBuy(
          spsAmmount,
          escrowAgentAddress,
          bytes,
          type,
          priceWei,
          parseInt(tokenSwap),
          coin, // tokenTo
          weiAmmountSwap
        )
        .send({
          feeLimit: 500_000_000
        })
    else
      txNetwork = p2pContract.methods
        .newEscrowSellOrBuy(
          spsAmmount,
          escrowAgentAddress,
          bytes,
          type,
          priceWei,
          parseInt(tokenSwap),
          coin, // tokenTo
          weiAmmountSwap
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          console.log(err);
          setLoading(false)
          e.target.disabled = false
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt);
        setLoading(false)
        e.target.disabled = false
        swal(t("r.good_job"), t("r.offer_complete_check"), "success")
        e.target.reset();
        navigate("/History");
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
        swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
      })
  }

  // funcion en utils para actualizar los saldos del usuraio del tokens swap
  // y consultarlos desde aqui y otros componentes
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.target.disabled = true
    setLoading(true)

    if (tokenSwap <= 1 && bankList.length === 0) {
      swal({
        title: t("r.no_regist_bank"),
        text: t("r.exchange_fiat"),
        icon: "warning",
        dangerMode: false,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: t("cancel"),
        confirmButtonText: "Ir a Perfil",
        buttons: [t("cancel"), t("to_profile")],
      })
        .then(response => {
          if (response) {
            e.target.reset();
            navigate("/Profile");
          }
        })
      e.target.disabled = false
      setLoading(false)
      return;
    }

    var abi = web3Utils.getAbiByAddress[tokenAddressById[coin]] // abi tokenTo
    var addressTokenTo = tokenAddressById[coin]
    const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
    await spsTokenContract.methods.allowance(window.ethereum.selectedAddress, await web3Utils.getP2PContractAddress())
      .call()
      .then(async ammount => {
        let toBNAmount = await web3Utils.toBN(ammount.toString())
        let toBnWeiAmmount = await web3Utils.toBN(web3Utils.toWei(weiAmmount.toString(), 'ether'))
        let desicion = false;
        if (web3Utils.getUserSesionData().provider === 3) { // tron
          if (ammount.toString() > 0 && toBnWeiAmmount.isLessThanOrEqualTo(toBNAmount)) {
            desicion = true
          }
        } else { // bnb
          if (ammount.toString() > 0 && toBnWeiAmmount <= toBNAmount) {
            desicion = true
          }
        }

        if (desicion) {
          return newEscrow(e)
        }
        else {
          swal({
            title: t("approve"),
            text: t("q.use_token", { coin: tokenNamesById[coin] }),
            icon: "warning",
            buttons: true,
            dangerMode: false,
          })
            .then(async result => {
              if (result) {
                const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
                await spsTokenContract.methods.approve(await web3Utils.getP2PContractAddress(), await web3Utils.getAmmountToApprove()).send({ from: window.ethereum.selectedAddress })
                  .then(() => {
                    swal(t("approve"), t("r.balance_approved_offer"), "success")
                    return newEscrow(e)
                  })
                  .catch(er => {
                    console.log(er)
                    e.target.disabled = false
                    setLoading(false)
                  })
              }
              else {
                e.target.disabled = false
                setLoading(false)
              }
            })
        }
      })
      .catch(err => {
        console.log(err)
        e.target.disabled = false
        setLoading(false)
      })
  }


  const getActiveScrowerList = async () => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PSellerScrowerContractAddress(), web3Utils.sellerScrowerAbi)
    return await p2pContract.methods
      .getActiveScrowerList()
      .call()
      .then(result => {
        if (result === '0' || result === undefined)
          setScrowerList(["0x47b833781a04AB68777f0C3881009b0103e3666A"])
        else {
          setScrowerList(result)
        }
      })
      .catch(err => {
        console.log(err)
        setScrowerList(["0x47b833781a04AB68777f0C3881009b0103e3666A"])
      })
  }

  const getBankList = async () => {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    axios.get('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/bank')
      .then((result) => {
        setBankList(result.data.banks)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getChaturbateList = async () => {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    axios.get('https://sparklifep2p.com:8080/api/v1/user/' + window.ethereum.selectedAddress + '/chaturbate')
      .then((result) => {
        setChaturbateList(result.data.banks)
      })
      .catch(err => {
        console.log(err)
      })
  }

  /**
   * 
   * @param {object} e 
   * @param {int} auxTokenSwap 
   */
  const handleChangeActivoIntercambiar = (e, auxTokenSwap = -1) => {
    setCoin(e.value)
    setCoinSelected(e)
    setTokenToName(tokenNamesById[e.value])
    getCurrentTokenPrice(e.value, auxTokenSwap >= 0 ? auxTokenSwap : tokenSwap)
  }

  /**
   * 
   * @param {object} e 
   * @param {int} auxCoin 
   */
  const handleChangeActivoIntercambio = (e, auxCoin = -1) => {
    if (e.value <= 1) getBankList()
    //TODO si el activo es Chaturbate mostrar la lista de códigos de Chaturbate del usuario
    if (e.value === "chaturbate") getChaturbateList()
    setTokenSwap(e.value)
    setTokenSwapSelected(e)
    setTokenSwapName(tokenNamesById[e.value])
    getCurrentTokenPrice(auxCoin >= 0 ? auxCoin : coin, e.value)
  }

  /**
   * Intercambia los valores entre el Select de Activo a Intercambiar,
   * y el Select de Activo de Intercambio
   */
  function handleClickIntercambiar() {
    const auxCoin = coinSelected
    const auxTokenSwap = tokenSwapSelected
    handleChangeActivoIntercambiar(auxTokenSwap, coin);
    handleChangeActivoIntercambio(auxCoin, tokenSwap)
  }

  const handleChangeBank = (e) => {
    setBank(e.target.value)
  }


  const handleChangeChaturbate = (e) => {
    setChaturbate(e.target.value)
  }

  function addValueToPrice(isRest, otherPrice = 0) {
    let sumRest = 1

    if (isRest)
      sumRest = -1

    if (isRest === -1)
      sumRest = 0

    let price = parseFloat(copPriceFixed)
    let pricePorcent = copPricePorce

    // Si se quiere calcular con un valor especifico
    if (otherPrice !== 0) {
      price = otherPrice
    }

    if (isNaN(pricePorcent))
      pricePorcent = price

    // Validar rangos >=-100 <= 100
    if (pricePorcent > 100) {
      pricePorcent = 100
    }
    else {
      if (pricePorcent < -100) {
        pricePorcent = -100
      }
    }

    let currentValue = parseFloat(pricePorcent) + sumRest

    // No aumentar %
    // if(otherPrice !== 0){
    //   currentValue = 0
    // }
    if (currentValue > 100)
      return false
    else
      if (currentValue < -100)
        return

    setCopPricePorce(currentValue) // Agregar numero porcentaje
    let lastPrice = Number(price) + (price * (currentValue / 100)) // Calcular el precio segun el %
    setCopPrice(lastPrice.toFixed(8)) // asignar el precio
    if (!weiAmmount) {
      setTotalCantPrice(0)
      setTotalFee(0)
    }
    else {
      let totalPrice
      // Obtener el fee y calcular el total
      /*if(parseInt(coin) === tokens.USDT || parseInt(coin) === tokens.BUSD)
        totalPrice = parseFloat(weiAmmount)/lastPrice
      else*/
      totalPrice = lastPrice * parseFloat(weiAmmount)

      let fee = totalPrice * 0.005
      setTotalCantPrice((totalPrice - fee).toFixed(8))
      setTotalFee(fee.toFixed(10))
    }
    return
  }

  /**
   * Actualizar los totales y fees segun la cantidad y precio seleccionado
   * @returns 
   */
  function updateValuePorcent(porc) {
    let price = parseFloat(copPriceFixed)
    let currentValue = parseFloat(porc)
    if (currentValue > 100)
      return false
    else
      if (currentValue < -100)
        return

    let lastPrice = price + (price * (currentValue / 100))
    setCopPrice(lastPrice.toFixed(8))
    let totalPrice = lastPrice * parseFloat(weiAmmount)
    let fee = totalPrice * 0.005
    setTotalCantPrice((totalPrice - fee).toFixed(8))
    setTotalFee(fee.toFixed(10))
    return
  }

  async function getUsdInCop() {
    const API_KEY = '1eb30da5e676ba03a7b56214';
    const endpoint = `https://v6.exchangerate-api.com/v6/${API_KEY}/pair/USD/COP`;

    return fetch(endpoint)
      .then(response => response.json())
      .then(data => {
        const usdToCopRate = data.conversion_rate;
        console.log(`1 USD = ${usdToCopRate} COP`);
        return usdToCopRate
      })
      .catch(error => console.error(error));
  }

  /**
   * SPS Prices
   */
  async function getPriceSPS_USDT() {
    return new Promise(async (resolve, reject) => {
      try {
        let amountOut, bnbPrice
        // Traer el precio sps en bnb
        amountOut = await getSpsVsBnb()
        // Traer el precio del bnb en USDt
        bnbPrice = await calcBNBPrice()
        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = amountOut * bnbPrice;
        resolve(usdSpsPrice)
        //resolve(0.000981041634)
      } catch (err) {
        reject(err)
      }
    })
  }

  function getPriceSPS_BTC() {
    return new Promise(async (resolve, reject) => {
      try {
        let amountOutInBnb, bnbPrice
        // Traer el precio sps en bnb
        amountOutInBnb = await getSpsVsBnb()

        // Traer el precio del bnb en USDt
        bnbPrice = await calcBNBPrice()

        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = amountOutInBnb / bnbPrice
        //let usdSpsPrice = 0.000981041634
        // Obtener el precio del BTC en USD
        let btcUsdtPrice = await getUsdPrice(0) // 0 = BTC
        // Dividir el precio del sps en USD en el total de USD que equivale 1 BTC
        //usdSpsPrice = usdSpsPrice / btcUsdtPrice
        usdSpsPrice = 1 * usdSpsPrice / btcUsdtPrice
        resolve(usdSpsPrice)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getSpsInUsd() {
    let amountOutInBnb, bnbPrice
    // Traer el precio sps en bnb
    amountOutInBnb = await getSpsVsBnb()

    // // Traer el precio del bnb en USDt
    bnbPrice = await calcBNBPrice()

    // // Calcular sps en usdt basado en el precio del bnb y el usdt
    let usdSpsPrice = amountOutInBnb * bnbPrice
    return usdSpsPrice
    // return 0.000981041634
  }

  function getPriceSPS_BCA() {
    return new Promise(async (resolve, reject) => {
      try {

        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = await getSpsInUsd()

        // Obtener el precio del BCA en USD
        let bcaUsdtPrice = 1.1
        // Dividir el precio del sps en USD en el total de USD que equivale 1 BCA
        usdSpsPrice = usdSpsPrice / bcaUsdtPrice
        resolve(usdSpsPrice)
      } catch (err) {
        reject(err)
      }
    })
  }

  function getPriceSPS_COP() {
    return new Promise(async (resolve, reject) => {
      try {

        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = await getPriceSPS_USDT()
        let usdToCop = await getUsdInCop()
        // Obtener el precio del SPS en COP 
        let bcaUsdtPrice = usdSpsPrice * usdToCop

        resolve(bcaUsdtPrice)
      } catch (err) {
        reject(err)
      }
    })
  }
  /**
   * Fin SPS Prices
   */

  /**
   * USDT & BUSD Prices
   */
  async function getPriceUSDT_SPS() {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(1 / await getSpsInUsd())
      } catch (err) {
        reject(err)
      }
    })
  }

  // USDT vs BTC. cuantos btcs equivalen 1 usdt
  async function getPriceUSDT_BTC() {
    return new Promise(async (resolve, reject) => {
      try {

        // BTC
        let btcUsdtPrice = await getUsdPrice(0) // 0 = BTC
        let price = 1 / btcUsdtPrice
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceUSDT_BCA() {
    return new Promise(async (resolve, reject) => {
      try {

        // bca usd price
        let btcUsdtPrice = 1.1
        let price = 1 * btcUsdtPrice
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceUSDT_COP() {
    return new Promise(async (resolve, reject) => {
      try {
        let price = 1 * (await getUsdInCop())
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }
  /**
   * Fin Price USD
   */

  /**
   * Prices BCA
   */
  function getPriceBCA_SPS() {
    return new Promise(async (resolve, reject) => {
      try {

        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = await getSpsInUsd()

        // Obtener el precio del BCA en USD
        let bcaUsdtPrice = 1.1
        // Dividir el precio del sps en USD en el total de USD que equivale 1 BCA
        usdSpsPrice = 1.1 / (usdSpsPrice * bcaUsdtPrice)
        resolve(usdSpsPrice)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBCA_USDT() {
    return new Promise(async (resolve, reject) => {
      try {

        // bca usd price
        let btcUsdtPrice = 1.1
        let price = 1 * btcUsdtPrice
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBCA_BTC() {
    return new Promise(async (resolve, reject) => {
      try {

        // BTC
        let btc1UsdtPrice = await getUsdPrice(0) // 0 = BTC
        let bcaInBtc = 1.1 / btc1UsdtPrice
        resolve(bcaInBtc)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBCA_COP() {
    return new Promise(async (resolve, reject) => {
      try {
        let price = 1.1 * (await getUsdInCop())
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }
  /**
   * Final Prices BCA
   */

  /**
   * Prices BTC
   */
  function getPriceBTC_SPS() {
    return new Promise(async (resolve, reject) => {
      try {

        // Calcular sps en usdt basado en el precio del bnb y el usdt
        let usdSpsPrice = await getSpsInUsd()

        // Obtener el precio del BTC en USD
        let btcUsdtPrice = await getUsdPrice(0) // 0 = BTC
        // dividir el equivalente del sps en usd en el precio del btc en usd
        // let btcSpsPrice = usdSpsPrice / btcUsdtPrice
        let btcSpsPrice = 1 / usdSpsPrice * btcUsdtPrice
        resolve(btcSpsPrice)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBTC_USDT() {
    return new Promise(async (resolve, reject) => {
      try {
        // BTC
        let btcUsdtPrice = await getUsdPrice(0) // 0 = BTC
        resolve(btcUsdtPrice)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBTC_BCA() {
    return new Promise(async (resolve, reject) => {
      try {
        // BTC
        let btc1UsdtPrice = await getUsdPrice(0) // 0 = BTC
        let btcInBca = btc1UsdtPrice / 1.1
        resolve(btcInBca)
      } catch (err) {
        reject(err)
      }
    })
  }

  async function getPriceBTC_COP() {
    return new Promise(async (resolve, reject) => {
      try {
        let price = await getPriceBTC_USDT() * await getUsdInCop()
        resolve(price)
      } catch (err) {
        reject(err)
      }
    })
  }
  /**
   * Fin Price BTC
   */

  /**
   * Segun el token seleccionado, se obtiene el valor en los mercados USDT
   * @param {*} _coin 
   * @param {*} tokenVs 
   */
  async function getCurrentTokenPrice(_coin, tokenVs) {
    let finalPrice = 0
    /**
     * Determinar el precio del mercado segun el Par
     */
    switch (parseInt(_coin)) {
      /**
       * SPS
      */
      case tokens.SparkLifeToken:
        // * Si es VS USDT o BUSD
        if (tokenVs === tokens.USDT || tokenVs === tokens.BUSD) {
          finalPrice = await getPriceSPS_USDT()
        }
        // BTC
        else if (tokenVs === tokens.BtcBinance || tokenVs === tokens.BtcsSpark) {
          finalPrice = await getPriceSPS_BTC()
        }
        // BCA
        else if (tokenVs === tokens.BancannabisToken) {
          finalPrice = await getPriceSPS_BCA()
        }
        // Fiat USD
        else if (tokenVs === tokens.FiatUsd) {
          finalPrice = await getPriceSPS_USDT()
        }
        // Fiat COP
        else if (tokenVs === tokens.FiatCop) {
          finalPrice = await getPriceSPS_COP()
        }
        else {
          finalPrice = parseFloat("1").toFixed(6)
        }
        break
      /**
       * USDT & BUSD
       */
      case tokens.USDT:
      case tokens.BUSD:
        if (tokenVs === tokens.SparkLifeToken) {
          finalPrice = await getPriceUSDT_SPS()
        }
        // BTC
        else if (tokenVs === tokens.BtcBinance || tokenVs === tokens.BtcsSpark) {
          finalPrice = await getPriceUSDT_BTC()
        }
        // BCA
        else if (tokenVs === tokens.BancannabisToken) {
          finalPrice = await getPriceUSDT_BCA()
        }
        // Fiat USD
        else if (tokenVs === tokens.FiatUsd) {
          finalPrice = 1
        }
        // Fiat COP
        else if (tokenVs === tokens.FiatCop) {
          finalPrice = await getPriceUSDT_COP()
        }
        else {
          finalPrice = parseFloat("1").toFixed(6)
        }
        break
      /**
       * BCA
       */
      case tokens.BancannabisToken:
        if (tokenVs === tokens.SparkLifeToken) {
          finalPrice = await getPriceBCA_SPS()
        }
        // USDT & BUSD
        else if (tokenVs === tokens.USDT || tokenVs === tokens.BUSD) {
          finalPrice = await getPriceBCA_USDT()
        }
        // BTC
        else if (tokenVs === tokens.BtcBinance || tokenVs === tokens.BtcsSpark) {
          finalPrice = await getPriceBCA_BTC()
        }
        // Fiat USD
        else if (tokenVs === tokens.FiatUsd) {
          finalPrice = 1.1
        }
        // Fiat COP
        else if (tokenVs === tokens.FiatCop) {
          finalPrice = await getPriceBCA_COP()
        }
        else {
          finalPrice = parseFloat("1.1").toFixed(6)
        }
        break
      /**
       * BTC
       */
      case tokens.BtcBinance:
      case tokens.BtcsSpark:
        if (tokenVs === tokens.SparkLifeToken) {
          finalPrice = await getPriceBTC_SPS()
        }
        // USDT & BUSD
        else if (tokenVs === tokens.USDT || tokenVs === tokens.BUSD) {
          finalPrice = await getPriceBTC_USDT()
        }
        // BTC
        else if (tokenVs === tokens.BancannabisToken) {
          finalPrice = await getPriceBTC_BCA()
        }
        // Fiat USD
        else if (tokenVs === tokens.FiatUsd) {
          finalPrice = await getPriceBTC_USDT()
        }
        // Fiat COP
        else if (tokenVs === tokens.FiatCop) {
          finalPrice = await getPriceBTC_COP()
        }
        else {
          finalPrice = 1
        }
        break
      default:
        break
    }
    setCopPriceFixed(parseFloat(finalPrice).toFixed(14))
    setCopPrice(parseFloat(finalPrice).toFixed(10))
    addValueToPrice(-1, finalPrice)
    //updateValuePorcent()
  }

  async function getSpsVsBnb() {
    let amountOut = 0;
    await axios.get('https://e-groweed.com:3800/api/v1/contract/pancakeswapspsprice?nid=56')
      .then((value) => {
        amountOut = value.data
      })
      .catch(err => {
        console.log(err)
      })
    return amountOut;

  }

  async function calcBNBPrice() {
    let amountOut = 0;
    await axios.get('https://e-groweed.com:3800/api/v1/contract/pancakeswapusdtprice?nid=56')
      .then((value) => {
        amountOut = value.data
      })
      .catch(err => {
        console.log(err)
      })
    return amountOut;
  }

  function getUsdPrice(networkId) {
    return new Promise(async (resolve, reject) => {
      try {
        let ticker = new binance({
          APIKEY: process.env.BINANCE_API_KEY,
          APISECRET: process.env.BINANCE_API_SECRET
        })
        let parPrice = await ticker.prices()
        switch (parseFloat(networkId)) {
          case 0:
            resolve(parPrice.BTCUSDT)
            break
          case 1:
            resolve(parPrice.ETHUSDT)
            break
          case 97:
          case 56:
          case 5777:
            resolve(parPrice.BNBUSDT)
            break
          case 137:
            resolve(parPrice.MATICUSDT)
            break
          default:
            resolve(0)
            break
        }
      } catch (err) {
        console.log(err);
        reject(err)
      }
    })
  }



  useEffect(() => {
    web3Utils.getTokentList()
      .then(list => {
        setTokenList(list)
        if (!coinSelected)
          setCoinSelected({
            value: list[2].id,
            label: list[2].name,
            image: coins[list[2].id]
          })
        if (!tokenSwapSelected)
          setTokenSwapSelected({
            value: list[4].id,
            label: list[4].name,
            image: coins[list[4].id]
          })
        let tokenById = []
        let tokenAddressById = []
        let i = 0
        list.forEach(token => {
          token.name = token.name + (token.name === "BUSD" && web3Utils.getUserSesionData().provider === 3 ? " (inactive)" : "")
          tokenById[token.id] = token.name
          tokenAddressById[token.id] = token.address
          i++
          if (list.length === i) {
            setTokenNamesById(tokenById)
            setTokenAddressById(tokenAddressById)
            setTokenToName(tokenById[2])
            setTokenSwapName(tokenById[4])
            getActiveScrowerList()
          }
        })

      })
      .catch(e => {
        console.log(e)
      })
    getCurrentTokenPrice(coin, tokenSwap)
    getBankList()
  }, [])

  return (
    <>

      <div className="banner-P2P"><h1>P2P</h1><h2>{t("create_offer")}</h2></div>

      <div id="SellOffers" className="container">
        <form onSubmit={handleSubmit} className=" container-selloffers">
          <div className="row">
            <div className="col-12 col-sm-6">
              <legend><b>{t("assets_trade")}</b></legend>
              <br />
              <div className="row">
                <label className="col-sm-4">{t("asset_exchange")}</label>
                <Select
                  className="col-sm-7"
                  value={coinSelected}
                  onChange={handleChangeActivoIntercambiar}
                  options={
                    tokenList.map((token, index) => {
                      return ({
                        key: index,
                        value: token.id,
                        label: token.name,
                        image: coins[token.id]
                      })
                    })
                  }
                  formatOptionLabel={token => (
                    <div className="option-image">
                      <img src={token.image} alt={token.label} />
                      <span>{token.label}</span>
                    </div>
                  )}
                />
              </div>
              <div className="row justify-content-center">
                <button className="btn btn-secondary col-8 col-sm-4" type="button" onClick={handleClickIntercambiar}>
                  {t("exchange")} <RefreshCw />
                </button>
              </div>
              <div className="row">
                <label className="col-sm-4">{t("exchange_asset")}</label>
                <Select
                  className="col-sm-7"
                  value={tokenSwapSelected}
                  onChange={handleChangeActivoIntercambio}
                  options={
                    tokenList.map((token, index) => {
                      return ({
                        key: index,
                        value: token.id,
                        label: token.name,
                        image: coins[token.id]
                      })
                    })
                  }
                  formatOptionLabel={token => (
                    <div className="option-image">
                      <img src={token.image} alt={token.label} />
                      <span>{token.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>
            <hr className="d-block d-sm-none"></hr>
            <div className="col-12 col-sm-6">
              <legend><b>{t("assign_quantity_price")}</b></legend>
              <br />

              <div className="row ">
                <label className="col-sm-4" for="inputCantidadToken">{t("quantity")} {tokenToName}</label>
                <div className="col-sm-7">
                  <input
                    className="form-control"
                    id="inputCantidadToken"
                    value={weiAmmount}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setTotalCantPrice(0)
                        setTotalFee(0)
                        setWeiAmmount(0)
                      }
                      else {
                        setWeiAmmount(parseInt(e.target.value))
                      }
                    }}
                    onKeyUp={(e) => {
                      addValueToPrice(-1)
                    }}
                    min={0}
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-sm-4" >{t("commission")} P2P ({tokenSwapName})</label>
                <div className="col-sm-7">
                  <input
                    value={"0.5% = " + totalFee}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-sm-4"><b>{t("total")} {tokenSwapName}s</b></label>
                <div className="col-sm-7">
                  <input
                    value={totalCantPrice}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
            </div>
            <hr className="d-block d-sm-none"></hr>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6">
              <legend><b>{t("exchange_value")}</b></legend>
              <br />
              <label>{t("current_value")} {tokenToName}: </label><b>{copPriceFixed} {tokenSwapName}</b><br />
              <label><a href="!#" style={{ textDecoration: "none" }}>{t("see_market")} {tokenToName}</a></label>
              <div className="row">
                <label className="col-sm-4">{t("price")}  {tokenToName}</label>
                <div className="col-sm-7 input-group">
                  <input
                    value={copPriceFixed}
                    className="form-control"
                    type="number"
                    disabled
                  />
                  <span className="input-group-text">{tokenSwapName}</span>
                </div>
              </div>
              <div className="row">
                <label className="col-sm-4">{t("percentage")}: </label>
                <div className="col-sm-7 input-group">
                  <input
                    min={-100}
                    value={copPricePorce}
                    onChange={(e) => {
                      setCopPricePorce(e.target.value)
                      updateValuePorcent(e.target.value)
                    }}
                    className="form-control"
                    type="number"
                    step="0.1"
                    required
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="row">
                <label className="col-sm-4">{t("new_price")}: </label>
                <div className="col-sm-7 input-group">
                  <input
                    value={copPrice}
                    onChange={(e) => {
                      setCopPrice(e.target.value)
                      // Porcentaje equivalente al nuevo precio
                      const porc = (((e.target.value / copPriceFixed) - 1) * 100).toFixed(2)
                      setCopPricePorce(porc)
                      updateValuePorcent(porc)
                    }}
                    className="form-control"
                    type="number"
                    required
                  />
                  <span className="input-group-text">{tokenSwapName}</span>
                </div>
              </div>
            </div>
            <hr className="d-block d-sm-none"></hr>
            <div className="col-12 col-sm-6">
              <legend><b>{t("validating_agent")}</b></legend>
              <br />
              <div className="row">
                <label className="col-sm-4">{t("validating_agent")}</label>
                <div className="col-sm-7">
                  <select
                    value={escrowAgentAddress}
                    onChange={(e) => setEscrowAgentAddress(e.target.value)}
                    className="form-select"
                  >
                    {scrowerList.map((scrowerAddress, index) => {
                      return (
                        <option
                          value={scrowerAddress}
                          key={scrowerAddress}>
                          Agente {index + 1}
                        </option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              {tokenSwap <= 1 ?
                <div className="row">
                  <label className="col-sm-4">{t("bank")}</label>
                  <div className="col-sm-7">
                    <select
                      value={bank}
                      onChange={handleChangeBank}
                      className="form-select"
                      required
                    >
                      {bankList.map((bank, index) => {
                        return (
                          <option
                            value={bank._id}
                            key={bank._id}>
                            {bank.name} {bank.account}
                          </option>
                        )
                      })
                      }
                    </select>
                  </div>
                </div> : <></>
              }
              {tokenSwap === "chaturbate" ?
                <div className="row">
                  <label className="col-sm-4">{t("chaturbate")}</label>
                  <div className="col-sm-7">
                    <select
                      value={chaturbate}
                      onChange={handleChangeChaturbate}
                      className="form-select"
                      required
                    >
                      {chaturbateList.map((chaturbate, index) => {
                        return (
                          <option
                            value={chaturbate._id}
                            key={chaturbate._id}>
                            {chaturbate.name} {chaturbate.account}
                          </option>
                        )
                      })
                      }
                    </select>
                  </div>
                </div> : <></>
              }
            </div>
          </div>

          {!isLoading ?
            <div className="row justify-content-center">
              <button className="btn btn-primary col-8 col-sm-4">
                {t("create_offer")}
              </button>
            </div>
            : ""}
          {isLoading === true ?
            <div className="SparklifeLoading">

              <Puff
                height="30"
                width="30"
                radisu={1}
                color="#f8359e"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />

            </div> : ""}
        </form >
      </div >
    </>
  );
};

export default BuyOffers;
