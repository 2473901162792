import React, { useState, useEffect } from "react";
// import Users from "../../Services/Users";
import "../../styles/BuySps.css";
import Offers from "../Offers";
import web3Utils from '../../Utils/web-utils'
import { Puff } from 'react-loader-spinner'
import Select from 'react-select';
import coins from "../../assets/monedas/index"
import { RefreshCw } from 'react-feather';
import axios from 'axios'
import { useTranslation } from 'react-i18next';

var isLoading = true
var namesByAddress = []
const BuySps = () => {
  const { t } = useTranslation();

  // if (typeof window.ethereum !== 'undefined') {
  //   if (window.ethereum.networkVersion !== '56') { // 56 es la default para Binance
  //     console.log("no 56 network")
  //   }
  // }
  // else{
  //   alert('Necesitas instalar MetaMask');
  // }


  // Listado tipo sell to buy typetrade=0 - para usuario final comprar
  const parseList = async (response) => {
    let result = []
    let totalRows = response[0].length
    for (let i = 0; i < totalRows; i++) {
      if (response[2][i] !== 0 && response[0][i] !== '0x0000000000000000000000000000000000000000') {
        result[i] = {}
        result[i]["id"] = parseInt(response[4][i])
        result[i]["typetrade"] = "Buy"
        result[i]["coin"] = namesByAddress[response[6][i]]
        result[i]["city"] = "dwad"
        result[i]["country_id"] = 1
        result[i]["currency"] = namesByAddress[response[1][i]]
        result[i]["user_first"] = ""
        result[i]["user_second"] = ""
        result[i]["payment_method_id"] = 1
        result[i]["extra_info_payment"] = "Nequi"
        result[i]["status_trade"] = web3Utils.toAscii(response[3][i])
        result[i]["created_at"] = "2022-04-28T17:38:52.347Z"
        result[i]["updated_at"] = "2022-04-28T17:38:52.347Z"
        result[i]["pricetrade"] = response[2][i] / (10 ** web3Utils.getDecimalsByAddress[response[6][i]])
        result[i]["pricecop"] = parseFloat(response[5][i]) / (10 ** 18)//web3Utils.getDecimalsByAddress[response[1][i]])
        result[i]["seller"] = response[0][i]
        result[i]["tokenTo"] = response[6][i]
        result[i]["tokenSwap"] = response[1][i]
        result[i]["rate"] = await getRate(response[0][i])
        result[i]["bank_name"] = await getSellerBank(response[0][i], parseInt(response[4][i]))
      }
    }

    return result
  }

  let filter1, filter2
  if (web3Utils.getUserSesionData().provider !== 3) {
    filter1 = "0x8033064Fe1df862271d4546c281AfB581ee25C4A"
    filter2 = "0x55d398326f99059fF775485246999027B3197955"
  }
  else {
    filter1 = "41c09b0801d2114bf549075f40ddda0a2ec31f97f9"
    filter2 = "41a614f803b6fd780986a42c78ec9c7f77e6ded13c"
  }

  const [filterCrypto, setFilterCrypto] = useState(filter1)
  const [filterCryptoSelected, setFilterCryptoSelected] = useState(null)
  const [filterPayMethod, setFilterPayMethod] = useState(filter2)
  const [filterPayMethodSelected, setFilterPayMethodSelected] = useState(null)
  const [buyTrades, setBuyTrades] = useState([]);
  const [buyTradesFilters, setBuyTradesFilters] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [tokenNamesByAddress, setTokenNamesByAddress] = useState([])
  const [priceOrderBy, setPriceOrderBy] = useState('1') // 0 asc, 1 desc


  const onFilterChange = (token, paymethod, traderArray = []) => {
    let trades = buyTrades
    if (traderArray.length > 0)
      trades = traderArray

    let filterTrades = trades.filter(trade => trade.tokenTo === token && trade.tokenSwap === paymethod)
    setBuyTradesFilters(filterTrades)
    applyOrderPrice(filterTrades, priceOrderBy)
  }

  /**
   * Ordena de acuerdo al preciocop
   * TODO considerar que se dé la opción de order por total
   * @param {Array} filterTrades 
   * @param {int} _priceOrderBy 
   */
  const applyOrderPrice = (filterTrades, _priceOrderBy) => {
    switch (_priceOrderBy) {
      // Pricecop - Precio por moneda de compra
      case '0':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => a.pricecop - b.pricecop))
        break
      case '1':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => b.pricecop - a.pricecop))
        break
      // Pricetrade - Cantidad de moneda de venta
      case '2':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => a.pricetrade - b.pricetrade))
        break
      case '3':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => b.pricetrade - a.pricetrade))
        break
      // Pricetrade - Cantidad de moneda de venta
      case '4':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => a.rate - b.rate))
        break
      case '5':
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => b.rate - a.rate))
        break
      default:
        setBuyTradesFilters(filterTrades.slice().sort((a, b) => b.pricecop - a.pricecop))
    }
  }

  async function getRate(addrSeller) {
    axios.defaults.headers['x-access-token'] = ''
    return axios.get('https://localcoin.digital:8080/api/v1/user/' + addrSeller + '/feedback/rate')
      .then((result) => {
        return result.data.rate
      })
      .catch(err => {
        console.log(err)
        return 0;
      })
  }

  /**
 * Devuelve el banco que el seller dispuso para esta transacción
 */
  async function getSellerBank(addrSeller, id) {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    return await axios.get('https://localcoin.digital:8080/api/v1/user/' + addrSeller + '/bank')
      .then(async (result) => {
        const extraInfoPayment = await getTransactionNote(addrSeller, id)
        if (extraInfoPayment) {
          const bank = result.data.banks.find(bank => bank._id === extraInfoPayment)
          if (bank)
            return bank.name
          else {
            return (await getSellerWallet(addrSeller)).bank
          }
        } else
          return (await getSellerWallet(addrSeller)).bank
      })
      .catch(err => {
        console.log(err)
      })
  }

  /**
 * Obtiene el valor [5] de la transacción en bytes32
 * Al convertirlo se obtiene el banco del vendedor(seller)
 * 
 * @returns 
 */
  async function getTransactionNote(addrSeller, id) {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    return await p2pContract.methods
      .getSpecificSellOrBuyTransaction(addrSeller, id)
      .call()
      .then(result => {
        return web3Utils.toAscii(result[5]).replace(/\x00/g, "")
      })
      .catch(err => {
        console.log(err)
      });
  }

  async function getSellerWallet(addrSeller) {
    axios.defaults.headers['x-access-token'] = ''
    return axios.get('https://localcoin.digital:8080/api/v1/user/' + addrSeller + '/wallet')
      .then(result => {
        return result.data.user
      })
  }

  function getNameButtonAction() {
    if (filterPayMethodSelected)
      if (filterPayMethodSelected.label)
        return filterPayMethodSelected.label.toUpperCase().includes("FIAT") ? t("reserve") : t("buy")

    return "Reservar y Comprar"
  }

  function handleClickIntercambio() {
    const auxCrypto = filterCryptoSelected
    const auxPayMethod = filterPayMethodSelected
    handleChangeCrypto(auxPayMethod, filterCrypto);
    handleChangeMetodoPago(auxCrypto, filterPayMethod)
  }

  const handleChangeCrypto = (e, auxPayMethod = -1) => {
    setFilterCrypto(e.value)
    setFilterCryptoSelected(e)
    onFilterChange(e.value, auxPayMethod >= 0 ? auxPayMethod : filterPayMethod)
  }

  const handleChangeMetodoPago = (e, auxCrypto = -1) => {
    setFilterPayMethod(e.value)
    setFilterPayMethodSelected(e)
    onFilterChange(auxCrypto >= 0 ? auxCrypto : filterCrypto, e.value)
  }

  const handleChangeOrder = (e) => {
    setPriceOrderBy(e.target.value)
    applyOrderPrice(buyTradesFilters, e.target.value)
  }

  useEffect(() => {
    const getBuyTrades = async () => {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      debugger
      return await p2pContract.methods
        .marketBuyOrSellOrders(30, 1)
        .call()
        .then(async result => {
          
          // if (result[0][0] === '0x0000000000000000000000000000000000000000')
          //   notRecords = true

          let list = await parseList(result)
          setBuyTrades(list)
          onFilterChange(await web3Utils.getSpsTokenAddress(), await web3Utils.getUSDTTokenAddress(), list); // SPS vs USDT
          isLoading = false
        })
        .catch(err => {
          console.log(err);
        });
    }

    web3Utils.getTokentList()
      .then(result => {
        setTokenList(result)
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            setTokenNamesByAddress(namesByAddress)
            getBuyTrades()
          }
        })

      })
  }, []);

  return (
    <>
      <div className="banner-P2P"><h1>P2P</h1><h2>{t("buy")} Sparklife</h2></div>

      <div id="optionsBar" className="container">
        <div className="row">
          <div className='col-12 col-lg-3'>
            <div className='row'>
              <label className="col-12 buy-tittle-label" htmlFor="sps">
                {t("crypto")}
              </label>
              <Select
                className="col-12"
                placeholder={t("select_currency")}
                id="sps"
                name="spsPrice"
                onChange={handleChangeCrypto}
                value={filterCryptoSelected}
                options={
                  tokenList.map((token, index) => {
                    return ({
                      key: index,
                      value: token.address,
                      label: token.name,
                      image: coins[token.id]
                    });
                  })
                }
                formatOptionLabel={token => (
                  <div className="option-image">
                    <img src={token.image} alt={token.label} />
                    <span>{token.label}</span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='col-6 col-lg-1 align-middle intercambio'>
            <div className='row'>
              <button className='btn btn-secondary' type="button" onClick={handleClickIntercambio}><RefreshCw /></button>
            </div>
          </div>
          <div className='col-12 col-lg-3'>
            <div className='row'>
              <label className="col-12 buy-tittle-label" htmlFor="pay">
                {t("payment_method")}
              </label>
              <Select
                className="col-12"
                placeholder={t("select_currency")}
                id="pay"
                name="pay"
                onChange={handleChangeMetodoPago}
                value={filterPayMethodSelected}
                options={
                  tokenList.map((token, index) => {
                    return ({
                      key: index,
                      value: token.address,
                      label: token.name,
                      image: coins[token.id]
                    });
                  })
                }
                formatOptionLabel={token => (
                  <div className="option-image">
                    <img src={token.image} alt={token.label} />
                    <span>{token.label}</span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='col-12 col-lg-4 '>
            <div className='row'>
              <label className="col-12 buy-tittle-label" htmlFor="pay">
                {t("order")}:
              </label>
              <div className="col-12 text-center">
                <select
                  className="form-select-lg buy-selector"
                  id="pay"
                  name="pay"
                  form="payForm"
                  onChange={handleChangeOrder}
                  value={priceOrderBy}
                >
                  <option value="0" key="0">{t("lowest_highest")} {t("price")}</option>
                  <option value="1" key="1">{t("highest_lowest")} {t("price")}</option>
                  <option value="2" key="2">{t("lowest_highest")} {t("amount")}</option>
                  <option value="3" key="3">{t("highest_lowest")} {t("amount")}</option>
                  <option value="4" key="4">{t("lowest_highest")} {t("qualification")}</option>
                  <option value="5" key="5">{t("highest_lowest")} {t("qualification")}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>

          <Puff
            height="80"
            width="80"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />

        </div> : ""
      }
      {
        buyTradesFilters.length === 0 ?
          <div className="container"><h1 className="sin-registros">Sin registros</h1></div> :
          <div className="container">
            {buyTradesFilters.map((trade, index) => {
              return (
                <Offers
                  key={index}
                  option={getNameButtonAction()}
                  typeTrade={trade.typetrade}
                  coin={trade.coin}
                  tokenNameByAddress={tokenNamesByAddress}
                  trade={trade}
                  currency={trade.currency}
                  city={trade.city}
                  id={trade.id}
                  pricetrade={trade.pricetrade}
                  seller={trade.seller}
                  swBuyOrSell="0"
                  pricecop={trade.pricecop}
                  buy_sell="/BuyConfirm/"
                  rate={trade.rate}
                  bank_name={trade.bank_name}
                />
              );
            })}
          </div>
      }
    </>
  );
};

export default BuySps;
