import BCA from './BCA.png';
import BTC from './BTC.png';
import BTCS from './BTCS.png';
import BUSD from './BUSD.png';
import FIATCOP from './FIAT COP.png';
import FIATUSD from './FIAT USD.png';
import SPS from './SPS.png';
import USDT from './USDT.png';

const coins = [
    FIATUSD, FIATCOP, USDT, BUSD, SPS, BCA, BTC, BTCS
]

export default coins;