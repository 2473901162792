import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import "../styles/NavBar.css";
import logo from "../assets/localcoin/logo_localcoin1.png"
import logoMetamask from "../assets/Metamask-logo.png"
import logoTronLink from "../assets/tronlink-logo.png"
import logoSparklifeWhite from "../assets/sparklife-logo-white.png"
import logoWalletConnect from "../assets/WalletConnect-logo.png"
import logoMetamaskDownload from "../assets/Metamask-logo-download.png"
import imgES from "../assets/lang/es.png"
import imgEN from "../assets/lang/en.png"
import backgroundPage from "../assets/Fondo-p2p.jpg"
import AddBuyer from "./Views/AddBuyer"
import Web3 from 'web3'
import axios from "axios"
import Talk from "talkjs"
import WalletConnectProvider from "@walletconnect/web3-provider"
import web3Utils from '../Utils/web-utils'
import { Puff } from 'react-loader-spinner'
import { ShoppingBag, Plus, Database, User, Send } from 'react-feather';
import { Dropdown } from "bootstrap";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';


var _walletConnectProvider;

const NavBar = () => {
  const langList = [
    {
      key: 0,
      value: 'es',
      label: "ES",
      image: imgES
    },
    {
      key: 1,
      value: 'en',
      label: "EN",
      image: imgEN
    }
  ]
  const { t, i18n } = useTranslation();
  const [hiddenGetUserData, setHiddenGetUserData] = useState(true)
  const [hiddenNeedWalletConnect, setHiddenNeedWalletConnect] = useState(true)
  const [hiddenNeedMetamask, setHiddenNeedMetamask] = useState(true)
  const [hiddenNeedTronLink, setHiddenNeedTronLink] = useState(true)
  const [showDownloadMetamask, setShowDownloadMetamask] = useState(false)
  const [loadingAccount, setLoadingAccount] = useState(true)
  const [userBalance, setUserBalance] = useState(0)
  const [ethSymbol, setEthSymbol] = useState('BNB')
  // 1 = Wallet Connect, 2 = Metamask, 0 = unstate
  const [currentProvider, setCurrentProvider] = useState(0)
  const [msgMetamask, setMsgMetamask] = useState(t("p2p_meta"))
  const [numCuenta, setNumCuenta] = useState("...")
  const [lang, setLang] = useState(langList[i18n.language === 'es' ? 0 : 1])

  const chatDiv = useRef()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const referral = searchParams.get("ref")
  if (referral)
    localStorage.setItem("ref", referral)

  // var loadingAccount = false
  var account

  // decrap
  /*function callMetamask(){
    return new Promise(async (resolve, reject) => {
      if (typeof window.ethereum !== 'undefined') {
        //console.log('MetaMask is installed!');
        await web3SetUp()
        await getAccount()
        if(await window.web3.eth.getChainId() !== '97'){ // 56 es la default para Binance
          await changeNetwork()
        }
        resolve()
      }
      else {
        //window.web3 = null;
        alert('You need install metamask')
        reject()
      }
    })
  }*/

  /**
   * Inicializar Wallet Connect
   */
  function initWalletConnect() {
    //validar
    //if(_walletConnectProvider)
    //  return

    _walletConnectProvider = new WalletConnectProvider({
      infuraId: "ad36d24efb634059af5c609b9872fbb6",
      rpc: {
        //97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/"
      },
      qrcodeModalOptions: {
        desktopLinks: [
          'tron',
          'ledger',
          'tokenary',
          'wallet',
          'wallet 3',
          'secuX',
          'ambire',
          'wallet3',
          'apolloX',
          'zerion',
          'sequence',
          'punkWallet',
          'kryptoGO',
          'nft',
          'riceWallet',
          'vision',
          'keyring'
        ],
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar",
        ],
      }
    })
    return _walletConnectProvider.enable()
  }

  /**
   * Destruir Wallet Connect
   */
  function destroyWalletConnect() {
    try {
      _walletConnectProvider = null
      beginSelectWallet()
      _walletConnectProvider.disconnect()
      _walletConnectProvider.close()
      //window.web3.eth.currentProvider.disconnect()  
    } catch (err) {

    }
  }

  /**
   * Abrir pagina para enlazar billetera segun la seleccion
   * @param {int} walletType 1 = WalletConnect, 2 = Metamask
   */
  function triggerSelectWallet(walletType) {
    setCurrentProvider(walletType)
    if (walletType === 2) {
      setHiddenNeedMetamask(false)
      setHiddenNeedTronLink(true)
      setHiddenNeedWalletConnect(true)
    }
    else if (walletType === 3) {
      setHiddenNeedMetamask(true)
      setHiddenNeedTronLink(false)
      setHiddenNeedWalletConnect(false)
    }
    else {
      setHiddenNeedMetamask(true)
      setHiddenNeedTronLink(true)
      setHiddenNeedWalletConnect(false)
    }
  }

  function updateUserData(data) {
    if (typeof data !== "string")
      data = JSON.stringify(data)

    localStorage.setItem("usrData", data);
  }

  /**
   * Si el usuario decide usar metamask debera inicializar su cuenta
   * Este metodo permite configurar la cuenta con metamask para usarla en el p2p
   * @returns promise
   */
  async function configMetamask() {
    return new Promise(async (resolve, reject) => {
      if (typeof window.ethereum !== 'undefined') {
        setHiddenNeedMetamask(false)
        setLoadingAccount(true)
        web3SetUp()
          .then(() => {
            getAccount()
              .then(async () => {

                try {
                  if (await window.web3.eth.getChainId() !== 56)
                    await changeNetwork()

                  handlesMetamask()
                  setHiddenNeedMetamask(true)
                  setHiddenNeedWalletConnect(true)
                  makeTalkSession()
                  let _balance = await window.web3.eth.getBalance(account) / 10 ** 18
                  updateUserData({
                    account: account,
                    provider: 2,
                    balance: _balance.toString(),
                    web3Init: 1
                  })
                  //setLoadingAccount(false)
                  setLoadingAccount(true)
                  setTimeout(() => {
                    resolve()
                    window.location.reload()
                  }, 1000)
                  resolve()
                } catch (err) {
                  reject()
                }
              })
              .catch(e => {
                //window.web3 = null
                setHiddenNeedMetamask(false)
                setShowDownloadMetamask(true)
                setMsgMetamask(t("no_meta"))
                reject(t("no_meta"))
                setLoadingAccount(false)
              })
          })
          .catch(e => {
            //window.web3 = null
            setHiddenNeedMetamask(false)
            setShowDownloadMetamask(true)
            setMsgMetamask(t("no_meta"))
            reject(t("no_meta"))
            setLoadingAccount(false)
          })
      }
      else {
        //window.web3 = null
        setHiddenNeedMetamask(false)
        setShowDownloadMetamask(true)
        setMsgMetamask(t("no_meta"))
        reject(t("no_meta"))
        setLoadingAccount(false)
      }
    })
  }

  /**
   * Si el usuario decide usar metamask debera inicializar su cuenta
   * Este metodo permite configurar la cuenta con metamask para usarla en el p2p
   * @returns promise
   */
  async function configTronLink() {
    return new Promise(async (resolve, reject) => {
      setHiddenNeedTronLink(true)
      setHiddenNeedMetamask(true)
      setHiddenNeedWalletConnect(true)
      setLoadingAccount(true)
      /*var timeOut = 60, seconds = 0
      let intervalTime = setInterval(() => {
        seconds++
        if(timeOut === seconds)
          clearInterval(intervalTime)
      }, 1000)*/
      var obj = setInterval(async () => {

        /*if(seconds => timeOut && !window.tronWeb.defaultAddress.base58) {
          disconnectTronLink()
          clearInterval(obj)
        }*/

        if ((window.tronWeb && window.tronWeb.defaultAddress.base58) || (window.tronWeb && !window.tronWeb.defaultAddress)) {
          clearInterval(obj)
          web3SetUp()
            .then(async () => {
              return getTronLinkSession()
            })

        }
      }, 10)
    })
  }

  function getTronLinkSession() {
    return new Promise(async (resolve, reject) => {
      let ac = await window.web3.request({ method: 'tron_requestAccounts' })
      if (ac.code !== 4001) {
        setUpDappTronLink()
        resolve()
        console.log("tronWeb successfully detected!")
      }
      else
        disconnectTronLink()
    })
  }

  function setCurrentAddress(addr) {
    try {
      window.ethereum.selectedAddress = addr
    } catch (error) {
      console.log(error)
    }
  }

  async function setUpDappTronLink() {
    try {
      var account = await window.web3.address.fromHex((await window.web3.trx.getAccount()).address.toString())
      //var account = (await window.web3.trx.getAccount()).address.toString()
      setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))

      // window.ethereum.selectedAddress = account
      setCurrentAddress(account)
      var balance = 0
      try {
        balance = await window.web3.trx.getBalance(account) / (10 ** 6)
        setUserBalance(parseFloat(balance).toFixed(6))
        balance = balance.toString()
      } catch (err) {
        console.log(err)
      }
      //makeTalkSession()
      updateUserData({
        account: account,
        provider: 3,
        balance: balance,
        web3Init: 1
      })

      setLoadingAccount(false)
      setHiddenNeedTronLink(true)
      /*setLoadingAccount(true)
      setTimeout(() => {
        resolve()
        window.location.reload()
      }, 1000)*/
      handlesTronLink()
      navigate("/")
    } catch (err) {
      console.log(err)
    }
  }

  function disconnectTronLink() {
    try {
      updateUserData({
        account: null,
        provider: 0,
        balance: 0,
        web3Init: 0
      })
      setCurrentProvider(0)
      setLoadingAccount(false)
      setHiddenGetUserData(true)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Si el usuario decide usar WalletConnect debera inicializar su cuenta
   * Configurar WalletConnect para usarlo en el p2p
   */
  async function configWalletConnect() {
    reduceWidthOR()
    await initWalletConnect()
      .then(async e => {

        handlesWalletConnect()
        account = e[0]
        // window.ethereum.selectedAddress = account
        setCurrentAddress(account)
        setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))

        updateUserData({
          account: account,
          provider: 1,
          balance: 0,
          web3Init: 1
        })

        if (!await existUserByWallet(account)) {
          setHiddenGetUserData(false)
        }

        // setHiddenNeedWalletConnect(true)
        // let chainId = await window.web3.eth.getChainId()
        // if(chainId === 97) {
        setLoadingAccount(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000);

        //}

      })
      .catch(async e => {
        destroyWalletConnect()
        setCurrentProvider(0)
      })
  }

  function reduceWidthOR() {
    let intervalId = setInterval(() => {
      if (document.getElementsByClassName("walletconnect-modal__base").length > 0) {
        document.getElementsByClassName("walletconnect-modal__base")[0].style.maxWidth = "250px"
        clearInterval(intervalId)
      }
    }, 300)
  }

  /**
   * Eventos Handle de Wallet Connect
   * (disconect, accountChange, chainChange)
   */
  function handlesWalletConnect() {
    if (!_walletConnectProvider) {
      initWalletConnect()
    }

    // Subscribe to accounts change
    _walletConnectProvider.on("accountsChanged", async (e) => {
      account = e[0]
      setCurrentAddress(account)
      // window.ethereum.selectedAddress = account
      if (account !== undefined) {
        if (await existUserByWallet(account))
          setHiddenGetUserData(true)
        else
          setHiddenGetUserData(false)
      }
      else {
        updateUserData({
          account: null,
          provider: 0,
          balance: 0,
          web3Init: 1
        })
        setHiddenNeedMetamask(false)
        navigate("/")
      }

      if (account)
        setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))
    })
    // Subscribe to chainId change
    _walletConnectProvider.on("chainChanged", (e) => {
      //account = ""
      if (e !== '0x38') {
        changeNetwork()
      }
      console.log(e)
    })
    // Subscribe to session disconnection
    _walletConnectProvider.on("disconnect", (code, reason) => {
      //alert("disconect!")
      account = null
      // window.ethereum.selectedAddress = null
      setCurrentAddress(null)
      console.log(code)
      console.log(reason)
      updateUserData({
        account: null,
        provider: 0,
        balance: 0,
        web3Init: 0
      })
      //destroyWalletConnect()
      setCurrentProvider(0)
      navigate("/")
    })
  }

  /**
   * Eventos Handle de Metamask
   * (disconect, accountChange, chainChange)
   */
  function handlesMetamask() {
    window.ethereum.on('disconnect', (e) => {
      account = null
      // window.ethereum.selectedAddress = null
      setCurrentAddress(null)
      updateUserData({
        account: null,
        provider: 0,
        balance: 0,
        web3Init: 0
      })
      console.log(e)
    })

    window.ethereum.on('accountsChanged', async (e) => {
      //alert("account change!")
      account = e[0]
      // window.ethereum.selectedAddress = account
      setCurrentAddress(account)
      if (account !== undefined) {
        if (await existUserByWallet(account))
          setHiddenGetUserData(true)
        else
          setHiddenGetUserData(false)

        let _balance = await window.web3.eth.getBalance(account) / 10 ** 18
        updateUserData({
          account: account,
          provider: 2,
          balance: _balance.toString(),
          web3Init: 1
        })
        setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))

      }
      else {
        updateUserData({
          account: null,
          provider: 0,
          balance: 0,
          web3Init: 0
        })
        setHiddenNeedMetamask(false)
      }
      navigate("/")
      console.log(account)
    })

    window.ethereum.on('chainChanged', (e) => {
      //alert("chain change!")
      //account = ""
      if (e !== '0x38') {
        changeNetwork()
      }
      console.log(e)
    })
  }

  function handlesTronLink() {
    window.addEventListener('message', async function (e) {
      // if (e.data.message && e.data.message.action === "tabReply") {
      //     console.log("tabReply event", e.data.message)
      //     if (e.data.message.data.data.node.chain === '_'){
      //         console.log("tronLink currently selects the main chain")
      //     }else{
      //         console.log("tronLink currently selects the side chain")
      //     }
      // }

      if (e.data.message && e.data.message.action === "setAccount") {


        console.log("setAccount event", e.data.message)
        console.log("current address:", e.data.message.data.address)
      }

      if (e.data.message && e.data.message.action === "setNode") {
        console.log("setNode event", e.data.message)
        if (e.data.message.data.node.chain === '_') {
          console.log("tronLink currently selects the main chain")
        } else {
          setHiddenNeedTronLink(false)
          console.log("tronLink currently selects the side chain")
        }

        // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 
        if (
          (e.data.message && e.data.message.action === "connect") ||
          (e.data.message && e.data.message.action === "accountsChanged") ||
          (e.data.message && e.data.message.action === "connectWeb") ||
          (e.data.message && e.data.message.action === "acceptWeb")
        ) {
          // window.ethereum.selectedAddress = e.data.message.data.address
          setCurrentAddress(e.data.message.data.address)
          account = window.ethereum.selectedAddress
          if (account !== undefined) {
            if (await existUserByWallet(account))
              setHiddenGetUserData(true)
            else
              setHiddenGetUserData(false)

            let _balance = await window.web3.trx.getBalance(account) / 10 ** 6
            updateUserData({
              account: account,
              provider: 3,
              balance: _balance.toString(),
              web3Init: 0
            })
            setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))
          }
          else {
            updateUserData({
              account: null,
              provider: 0,
              balance: 0,
              web3Init: 0
            })
            setHiddenNeedTronLink(false)
          }
          navigate("/")
          console.log("connect event", e.data.message.isTronLink)
        }

        // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support 
        if ((e.data.message && e.data.message.action === "disconnect") || (e.data.message && e.data.message.action === "rejectWeb")) {
          account = null
          setCurrentAddress(null)
          // window.ethereum.selectedAddress = null
          updateUserData({
            account: null,
            provider: 0,
            balance: 0,
            web3Init: 0
          })
          console.log("disconnect event", e.data.message.isTronLink)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support 
        if (e.data.message && e.data.message.action === "accountsChanged") {
          console.log("accountsChanged event", e.data.message)
          console.log("current address:", e.data.message.data.address)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support  
        if (e.data.message && e.data.message.action === "connectWeb") {
          console.log("connectWeb event", e.data.message)
          console.log("current address:", e.data.message.data.address)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support   
        if (e.data.message && e.data.message.action === "accountsChanged") {
          console.log("accountsChanged event", e.data.message)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support      
        if (e.data.message && e.data.message.action === "acceptWeb") {
          console.log("acceptWeb event", e.data.message)
        }
        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support      
        if (e.data.message && e.data.message.action === "disconnectWeb") {
          console.log("disconnectWeb event", e.data.message)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support     
        if (e.data.message && e.data.message.action === "rejectWeb") {
          console.log("rejectWeb event", e.data.message)
        }

      }
    })
  }


  // Config Web3
  function web3SetUp(optionalProvider = 0) {
    return new Promise((resolve, reject) => {
      try {
        let _currentProvider = currentProvider
        if (optionalProvider !== 0)
          _currentProvider = optionalProvider

        let oProvider = new Web3(_walletConnectProvider)
        if (_currentProvider === 2)
          oProvider = new Web3(window.web3.currentProvider)

        if (_currentProvider === 3)
          oProvider = window.tronWeb

        window.web3 = oProvider // new Web3(window.web3.currentProvider)
        resolve()
      } catch (err) {
        reject()
      }
    })
  }

  // Inicializar metamask
  async function getAccount() {
    return new Promise(async (resolve, reject) => {
      try {

        if (loadingAccount || account)
          return

        setLoadingAccount(true)
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        account = accounts[0]
        // window.ethereum.selectedAddress = account
        setCurrentAddress(account)
        setNumCuenta(account.substring(0, 5) + "..." + account.slice(-5))

        if (!await existUserByWallet(account)) {
          setHiddenGetUserData(false)
        }
        setHiddenNeedMetamask(true)
        setLoadingAccount(false)
        resolve()
      } catch (err) {
        setLoadingAccount(false)
        console.log(err)
        reject()
      }
    })
  }

  // Si existe un usuario o no
  async function existUserByWallet(walletAddress) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers['x-access-token'] = ''
      axios.get('https://localcoin.digital:8080/api/v1/user/' + walletAddress + '/exist')
        .then((result) => {
          resolve(result.data)
        })
        .catch(err => {
          console.log(err)
          reject(false)
        })
    })
  }

  // Cambiar network blockchain
  async function changeNetwork() {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x38", // 56
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        chainName: "Binance Smart Chain",
        nativeCurrency: {
          name: "Binance Coin",
          symbol: "BNB",
          decimals: 18
        },
        blockExplorerUrls: ["https://bscscan.com"]
      }]
    })
      .then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      });
  }

  /**
   * Inicializar chat
   */
  async function makeTalkSession() {
    Talk.ready
      .then(() => {

        const me = new Talk.User({
          id: window.ethereum.selectedAddress.toLowerCase(),
          name: "Chat con " + window.ethereum.selectedAddress.substring(0, 5) + "..." + window.ethereum.selectedAddress.slice(-5),
          email: null,
          role: "sellers"
        })

        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: "tiJYePJU",
            me: me,
          })

          window.talkSession.onDesktopNotificationClicked((result) => {
            console.log(result)
            var conversation = window.talkSession.getOrCreateConversation(
              result.conversation.id
            )
            window.popupChat.select(conversation)
            window.popupChat.show()
          })
        }

        //let inbox_ = window.talkSession.createInbox(conversation)
        window.popupChat = window.talkSession.createPopup()
        window.popupChat.select(undefined)
        window.popupChat.mount(chatDiv.current)

        setInterval(() => {
          try {
            if (document.getElementsByClassName("__talkjs_launcher open")[0]) {
              document.getElementsByClassName("__talkjs_launcher open")[0].style.display = "block"
              document.getElementsByClassName("__talkjs_popup")[0].style.height = "80%"
            }
          } catch (err) {
            console.log(err)
          }
        }, 5000)
      })
      .catch(e => console.error(e))
  }

  /**
   * Inicializar vistas para q el usuario decida que wallet usar
   */
  function beginSelectWallet() {
    updateUserData({
      account: null,
      provider: 0,
      balance: 0,
      web3Init: 0
    })
    setNumCuenta("")

    if (window.ethereum)
      setCurrentAddress(null)
      // window.ethereum.selectedAddress = null
    else
      window.ethereum = {
        selectedAddress: null
      }

    setCurrentProvider(0)
    setHiddenNeedMetamask(true)
    setShowDownloadMetamask(false)
    setHiddenNeedWalletConnect(true)
    setHiddenGetUserData(true)
    setMsgMetamask(t("p2p_meta"))
  }

  function verifyTRXConnection() {
    return new Promise(async (resolve, reject) => {
      // updateUserData({
      //   account: null,
      //   provider: 0,
      //   balance: 0,
      //   web3Init: 0
      // })
      let interv = setInterval(() => {
        if (window.tronWeb) {
          if (window.tronWeb.defaultAddress) {
            updateUserData({
              account: window.tronWeb.defaultAddress.base58,
              provider: 3,
              balance: 0,
              web3Init: 1
            })
            setHiddenNeedMetamask(true)
            setShowDownloadMetamask(false)
            setHiddenNeedWalletConnect(true)
            setCurrentProvider(3)
            clearInterval(interv)
          }
        }
      }, 100);

      // let data = web3Utils.getUserSesionData()
      // alert(data.account)
      resolve()
    })
  }

  function handleChangeLanguage(e) {
    setLang(e)
    i18n.changeLanguage(e.value);
    console.log(t('translation.test', 'Hola'))
  }

  useEffect(() => {

    //disconnectTronLink()
    // determinar el estado de la coneccion con la wallet y cargar por defecto los datos
    let data = web3Utils.getUserSesionData()
    if (!window.ethereum)
      window.ethereum = {
        selectedAddress: null
      }
    verifyTRXConnection()
      .then(async () => {
        if (data) {
          if (!data.account)
            data = null
        }
        if (data !== null && data.account) {
          setCurrentProvider(data.provider)
          //setEthSymbol((web3Utils.getUserSesionData().provider === 3)?"TRX":"BNB")
          if (data.account !== null) {
            setCurrentAddress(data.account)
            // window.ethereum.selectedAddress = data.account
            await existUserByWallet(data.account).then(result => {
              if (!result)
                setHiddenGetUserData(false)
            })
            await web3SetUp(data.provider) // init
            if (data.provider === 1)
              handlesWalletConnect()
            else if (data.provider === 2) {
              getAccount()
              handlesMetamask()
            }
            else if (data.provider === 3) {
              handlesTronLink()
            }

            setUserBalance(parseFloat(data.balance).toFixed(6))
            setInterval(async () => {
              let data = web3Utils.getUserSesionData()
              if (data.account === null || data.provider === 0) return
              let _balance = 0
              try {

                if (data.provider !== 3)
                  _balance = await window.web3.eth.getBalance(window.ethereum.selectedAddress) / 10 ** 18
                else {
                  if (window.web3.trx)
                    _balance = await window.web3.trx.getBalance(account) / 10 ** 6
                }

                updateUserData({
                  account: data.account,
                  provider: data.provider,
                  balance: _balance.toString(),
                  web3Init: 1
                })
                setUserBalance(parseFloat(_balance).toFixed(6))
              } catch (err) {
                console.log(err)
              }
            }, 10000)
            setNumCuenta(data.account.substring(0, 5) + "..." + data.account.slice(-5))
            makeTalkSession()
          }
        }
        else {
          setCurrentProvider(0)
          beginSelectWallet()
        }
        setLoadingAccount(false)
      })

  }, [])

  return (
    <>
      <div></div>
      {!hiddenGetUserData ?
        <div className="AddBuyerBackground" style={
          {
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            position: "fixed",
            color: "white",
            zIndex: "100",
            textAlign: "center",
            opacity: "0.9",
            overflowY: "auto",
            backgroundImage: 'url(' + backgroundPage + ')',
            margin: "0",
            fontFamily: "'Poppins', sans-serif, -apple-system BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale"
          }}>
          <img src={logo} alt="logo" className="LogoSparkAddBuyer" />
          <AddBuyer />
          <button className="offers-button" onClick={
            () => {
              disconnectTronLink()
            }
          }>{t("exit")}
          </button>
        </div> : ""}

      {loadingAccount === true ?
        <div className="AddBuyerBackground" style={
          {
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            position: "fixed",
            color: "white",
            zIndex: "101",
            textAlign: "center",
            opacity: "0.9",
            overflowY: "auto",
            backgroundImage: 'url(' + backgroundPage + ')',
            margin: "0",
            fontFamily: "'Poppins', sans-serif, -apple-system BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale"
          }}>
          <div style={{ left: "47%", top: "47%", position: "absolute" }}>
            <Puff
              height="30"
              width="30"
              radisu={1}
              color="#f8359e"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
            />
          </div></div> : ""}

      {!hiddenNeedMetamask && currentProvider === 2 ?
        <div className="content-wallet">
          <img className="logo-wallet" src={logoMetamask} alt="logo" /><br />
          {msgMetamask}<br /><br />

          <button className="btn btn-secondary" onClick={
            () => configMetamask()
          }>{t("connect_meta")}
          </button><br />

          <button className="btn btn-secondary" onClick={
            () => {
              beginSelectWallet()
            }
          }>{t("back")}
          </button><br /><br />

          {showDownloadMetamask ?
            <a href="https://metamask.io/download/" target="_target" style={{ textDecoration: "none", color: "white" }}>
              {t("get_meta")} <br />
              <img src={logoMetamaskDownload} style={{ width: "11%" }} alt="logo" />
            </a> : ""
          }
        </div> : ""}

      {!hiddenNeedTronLink && currentProvider === 3 ?
        <div className="content-wallet">
          <img className="logo-wallet" src={logoTronLink} alt="logo" /><br />
          {t("give_access_tronlink")}<br /><br />

          <button className="btn btn-secondary" onClick={
            () => configTronLink()
          }>{t("connect_tronlink")}
          </button><br />

          <button className="btn btn-secondary" onClick={
            () => {
              beginSelectWallet()
            }
          }>{t("back")}
          </button><br />

        </div> : ""}

      {currentProvider === 0 ?
        <div className="content-wallet">
          <img className="logo-wallet" src={logoSparklifeWhite} alt="logo" /><br />
          {t("type_wallet")}<br /><br />

          <div>
            <button className="btn btn-secondary" onClick={
              () => triggerSelectWallet(2)
            }>Metamask
            </button> &nbsp; &nbsp;
            <button className="btn btn-secondary" onClick={
              () => triggerSelectWallet(1)
            }>Wallet Connect
            </button> &nbsp; &nbsp;
            <button className="btn btn-secondary" onClick={
              () => triggerSelectWallet(3)
            }>TronLink
            </button>
            <br />
          </div>

        </div> : ""}

      {!hiddenNeedWalletConnect && currentProvider === 1 ?
        <div className="content-wallet">
          <img className="logo-wallet" src={logoWalletConnect} alt="logo" /><br />
          {t("connect_wallet_walletconnect")}<br /><br />

          <button className="btn btn-secondary" onClick={
            () => configWalletConnect()
          }>{t("connect_walletconnect")}
          </button><br />
          <button className="btn btn-secondary" onClick={
            () => {
              beginSelectWallet()
            }
          }>{t("back")}
          </button><br />

        </div> : ""}

      <div ref={chatDiv}></div>

      <nav className="navbar navbar-expand-md">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <NavLink to="/">
              <img src={logo} alt="logo" width="130px" />
            </NavLink>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item d-flex align-items-center justify-content-center">
                <NavLink className={({ isActive }) => (isActive ? "navbar-item active align-middle" : "navbar-item")} to="/BuySps"><ShoppingBag size={12} /> {t("market")}</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center">
                <NavLink className={({ isActive }) => (isActive ? "navbar-item active" : "navbar-item")} to="/SellOffers"><Plus size={12} /> {t("create_offer")}</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center">
                <NavLink className={({ isActive }) => (isActive ? "navbar-item active" : "navbar-item")} to="/History"><Database size={12} /> {t("history")}</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center">
                <a href="https://t.me/+bFNeMXFk5TIyY2M5" target="_target" style={{"textDecoration":"none"}}><Send size={18} color="#0088cc" />&nbsp;Telegram</a>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center">
                <Select
                  className="col-12"
                  id="lang"
                  name="lang"
                  onChange={handleChangeLanguage}
                  value={lang}
                  options={langList}
                  formatOptionLabel={language => (
                    <div className="option-image">
                      <img src={language.image} alt={language.label} />
                      <span>{language.label}</span>
                    </div>
                  )}
                />
              </li>
              <li className="nav-item dropdown d-md-flex align-items-center justify-content-center d-sm-none d-none">
                <button className="dropdown-toggle btn btn-secondary " role="button" data-bs-toggle="dropdown">
                  {t("account")}
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li className="nav-item-dropdown">
                    <NavLink className={({ isActive }) => (isActive ? "navbar-item active" : "navbar-item")} to="/Profile"><User size={12} /> {t("profile")}</NavLink>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li className="">{t("account")}: <b><span id="num_cuenta" name="num_cuenta">{numCuenta}</span></b></li>
                  <li className="">{ethSymbol} {t("balance")}: <b>{userBalance}</b> </li>
                  <li>
                    {currentProvider === 2 ?
                      <button className="btn btn-secondary" onClick={() => { beginSelectWallet() }}>{t("sign_off")}</button>
                      : currentProvider === 1 ?
                        <button className="btn btn-secondary" onClick={() => { destroyWalletConnect() }}>Disconect</button>
                        : currentProvider === 3 ?
                          <button className="btn btn-secondary" onClick={() => { disconnectTronLink() }}>Disconect</button>
                          : ""
                    }
                  </li>
                </ul>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center d-md-none">
                <NavLink className={({ isActive }) => (isActive ? "navbar-item active" : "navbar-item")} to="/Profile"><User size={12} /> {t("profile")}</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center d-md-none">
                <span>{t("account")}: <b><span id="num_cuenta" name="num_cuenta">{numCuenta}</span></b></span>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center d-md-none">
                <span>{ethSymbol} {t("balance")}: <b>{userBalance}</b></span>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center d-md-none">
                {currentProvider === 2 ?
                  <button className="btn btn-secondary" onClick={() => { beginSelectWallet() }}>{t("sign_off")}</button>
                  : currentProvider === 1 ?
                    <button className="btn btn-secondary" onClick={() => { destroyWalletConnect() }}>Disconect</button>
                    : currentProvider === 3 ?
                      <button className="btn btn-secondary" onClick={() => { disconnectTronLink() }}>Disconect</button>
                      : ""
                }
              </li>
            </ul>
          </div>
        </div>
      </nav >
    </>
  );
};

export default NavBar;
