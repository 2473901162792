import React, { useState, useEffect } from "react"
import "../styles/Offers.css"
import web3Utils from '../Utils/web-utils'
import { Link, useNavigate } from "react-router-dom"
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import swal from 'sweetalert'
import Rating from "./Rating"
import { useTranslation } from 'react-i18next';

const Offers = ({ option, typeTrade, coin, currency, infoPay, city,
  id, pricetrade, buy_sell, seller, swBuyOrSell, pricecop, tokenNameByAddress, trade, rate, bank_name }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [userBalance, setUserBalance] = useState(0)
  // const [feedBackRate, setFeedBackRate] = useState(0);
  const [bankSeller, setBankSeller] = useState("");
  const [extraInfoPayment, setExtraInfoPayment] = useState("");

  const validateAllowanceToken = () => {
    return new Promise(async (resolve, reject) => {
      var abi = web3Utils.getAbiByAddress[trade.tokenTo] // abi tokenTo
      var addressTokenTo = trade.tokenTo
      const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
      await spsTokenContract.methods.allowance(window.ethereum.selectedAddress, await web3Utils.getP2PContractAddress())
        .call()
        .then(async ammount => {
          if (ammount > 0 && (
            await web3Utils.toBN(web3Utils.toWei(ammount.toString(), 'ether')) >= await web3Utils.toBN(web3Utils.toBN(web3Utils.toWei('10000', 'ether')))
          )
          ) {
            resolve()
          }
          else {
            swal({
              title: `¡${t("confirm")}!`,
              text: t("q.token_swap_auth"),
              icon: "info",
              buttons: true,
              dangerMode: false,
            })
              .then(async result => {
                if (result) {
                  const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
                  await spsTokenContract.methods.approve(await web3Utils.getP2PContractAddress(), '1000000000000000000000000').send({ from: window.ethereum.selectedAddress })
                    .then(() => {
                      swal(t("r.good_job"), t("r.balance_approved"), "success")
                      resolve()
                    })
                    .catch(err => {
                      swal("¡Ouch!", t("r.confirm_cancel"), "warning")
                      setLoading(false)
                    })
                }
                else {
                  setLoading(false)
                  reject()
                }
              })
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          reject()
        })
    })
  }

  // Evento comprar/vender
  const offertButtonEvent = async (route) => {

    // validar el balance
    if (userBalance <= 0) {
      swal({
        title: `¡${t("confirm")}!`,
        text: t("r.bnb_balance_insufficient"),
        icon: "warning",
        button: true,
        dangerMode: true,
      })
      return
    }

    let isSwap = false
    let msg = t("q.apply_offer_list")
    if (
      trade.tokenSwap !== "0x0000000000000000000000000000000000000002" &&
      trade.tokenSwap !== "0x0000000000000000000000000000000000000001" &&
      trade.tokenSwap !== "410000000000000000000000000000000000000001" &&
      trade.tokenSwap !== "410000000000000000000000000000000000000002"
    ) {
      route = "/history"
      isSwap = true
      msg = t("q.swap_token_wallet")
    }

    swal({
      title: `¡${t("confirm")}!`,
      text: msg,
      icon: "success",
      buttons: true,
      dangerMode: false,
    })
      .then(async result => {
        if (result) {
          setLoading(true)

          if (isSwap) {
            await validateAllowanceToken()
          }

          const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
          var txNetwork
          if (web3Utils.getUserSesionData().provider === 3) {
            txNetwork = p2pContract.methods
              .ApplyOffertToEscrow(
                seller,
                id
              )
              .send({
                feeLimit: 500_000_000
              })
          }
          else
            txNetwork = p2pContract.methods
              .ApplyOffertToEscrow(
                seller,
                id
              )
              .send({ value: '0', from: window.ethereum.selectedAddress })
              .on('transactionHash', () => {
                console.log("Pending transaction... please wait")
              })
              .on('error', (err) => {
                console.log(err)
                setLoading(false)
              })

          return await txNetwork
            .then((receipt, param) => {
              console.log(receipt)
              let msg

              // 1 = Sell, 0 = Buy
              // si es 1, es porq se debe de alertar al vendedor que le hicieron la reserva a la Orden
              // Si no es un swap directo
              if (swBuyOrSell === 1 && !isSwap) {
                msg = t("r.offer_complete_transfer", { token: tokenNameByAddress[trade.tokenTo] })
                axios.post('https://localcoin.digital:8080/api/v1/notify/' + seller + '?f=' + window.ethereum.selectedAddress + '&t=3&id=' + id + '&ttn=' + tokenNameByAddress[trade.tokenTo] + '&tsn=' + tokenNameByAddress[trade.tokenSwap])
                  .then(() => {
                    setLoading(false)
                    swal(t("r.good_job"), msg, "success")
                  })
                  .catch(err => {
                    console.log(err)
                    swal("¡Ouch!", msg, "warning")
                    setLoading(false)
                  })
              }
              else if (!isSwap) {
                // Soy un comprador
                // alertar al vendedor que alguien hizo una intencion de oferta
                msg = "Se ha completado la transacción y notificado al vendedor. Por favor complete la transacción."
                axios.post('https://localcoin.digital:8080/api/v1/notify/' + seller + '?f=' + window.ethereum.selectedAddress + '&t=2&id=' + id + '&tp=' + (pricetrade * pricecop) + '&ttn=' + tokenNameByAddress[trade.tokenTo] + '&tsn=' + tokenNameByAddress[trade.tokenSwap])
                  .then(() => {
                    setLoading(false)
                    swal(t("r.good_job"), msg, "success")
                  })
                  .catch(err => {
                    console.log(err)
                    swal("¡Ouch!", msg, "warning")
                    setLoading(false)
                  })
              }
              else {
                // Notificar al comprador que ya se aprobaron los fondos
                axios.post('https://localcoin.digital:8080/api/v1/notify/' + seller + '?f=' + window.ethereum.selectedAddress + '&t=4&id=' + id + '&ttn=' + tokenNameByAddress[trade.tokenTo] + '&tsn=' + tokenNameByAddress[trade.tokenSwap])
                  .then(() => {
                    swal(t("r.good_job"), t("r.transaction_approve_inbox"), "success")
                    setLoading(false)
                  })
                  .catch(err => {
                    console.log(err)
                    swal("¡Ouch!", t("r.transaction_approve_inbox_no"), "warning")
                    setLoading(false)
                  })
              }
              navigate(route);
            })
            .catch(err => {
              setLoading(false)
              console.log(err)
              swal("¡Ouch!", t("r.no_transaction_wallet"), "warning")
            })

        }
      })
  }

  // async function getRate() {
  //   axios.defaults.headers['x-access-token'] = ''
  //   axios.get('https://localcoin.digital:8080/api/v1/user/' + seller + '/feedback/rate')
  //     .then((result) => {
  //       setFeedBackRate(result.data.rate)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }

  const parseList = (response) => {
    try {
      let result = {}
      // result["id"] = parseInt(response[4])
      // result["seller"] = sellerAddress
      // result["escrowerAgent"] = response[1]
      // result["pricetrade"] = response[6] / (10 ** web3Utils.getDecimalsByAddress[response[8]])
      // result["ammount"] = response[2] / (10 ** web3Utils.getDecimalsByAddress[response[7]])
      // result["status_trade"] = web3Utils.toAscii(response[3]).replace(/\x00/g, "")
      result["extra_info_payment"] = web3Utils.toAscii(response[5]).replace(/\x00/g, "")
      // result["typetrade"] = "Buy"
      // result["coin"] = namesByAddress[response[7]]
      // result["city"] = "a"
      // result["country_id"] = 1
      // result["currency"] = "USD"
      // result["user_first"] = sellerAddress
      // result["user_second"] = response[0] // buyer
      // result["payment_method_id"] = 1
      // result["created_at"] = "2022-04-28T17:38:52.347Z"
      // result["updated_at"] = "2022-04-28T17:38:52.347Z"
      // result["tokenTo"] = response[7]
      // result["tokenSwap"] = response[8]
      // result["tokenToName"] = namesByAddress[response[7]]
      // result["tokenSwapName"] = namesByAddress[response[8]]

      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const getSellerData = async () => {
    return new Promise((resolve, reject) => {
      axios.get('https://localcoin.digital:8080/api/v1/user/' + seller + '/wallet')
        .then(_sellerData => {
          // console.log(_sellerData.data.user)
          // setSellerData(_sellerData.data.user)
          setBankSeller(_sellerData.data.user.bank)
        })
    })
  }

  /**
   * Devuelve el banco que el seller dispuso para esta transacción
   */
  const getBankSeller = async () => {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    axios.get('https://localcoin.digital:8080/api/v1/user/' + seller + '/bank')
      .then((result) => {
        if (extraInfoPayment) {
          const bank = result.data.banks.find(bank => bank._id === extraInfoPayment)
          if (bank)
            setBankSeller(bank.name)
          else {
            getSellerData()
          }
        } else
          getSellerData()
      })
      .catch(err => {
        console.log(err)
      })
  }

  /**
   * Obtiene el valor [5] de la transacción en bytes32
   * Al convertirlo se obtiene el banco del vendedor(seller)
   * 
   * @returns 
   */
  const getData = async () => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    return await p2pContract.methods
      .getSpecificSellOrBuyTransaction(seller, id)
      .call()
      .then(result => {
        setExtraInfoPayment(web3Utils.toAscii(result[5]).replace(/\x00/g, ""))
        getBankSeller()
      })
      .catch(err => {
        console.log(err)
      });
  }


  useEffect(() => {
    // Obtener balance
    setUserBalance(web3Utils.getUserBalanceSessionData())
    getData()
  })

  return (
    <>
      <div id="offers" className="main-offers-container">
        <div className="container offers-container">
          <div className="row">
            <div className="col-12 col-sm-10 card-top">
              <div className="row">
                <div className="col-12 col-sm-5 card-top-info">
                  <p>{t("amount")}: <span className="card-value">{new Intl.NumberFormat('de-DE', {}).format(pricetrade).toString()} {tokenNameByAddress[trade.tokenTo]}</span><br />
                    {t("price")} {tokenNameByAddress[trade.tokenTo]}: <span className="card-value">{new Intl.NumberFormat('de-DE', {}).format(pricecop).toString().slice(0)} {tokenNameByAddress[trade.tokenSwap]}</span><br />
                    {option === t("reserve") &&
                      <div>{t("bank")}:<span className="card-value">{bank_name}</span><br /></div>
                    }
                  </p>
                </div>
                <div className="col-12 col-sm-5 card-top-info">
                  <p>
                    {t("total")}: <span className="card-value total">{new Intl.NumberFormat('de-DE', {}).format(parseFloat(pricetrade) * parseFloat(pricecop)).slice(0)} {tokenNameByAddress[trade.tokenSwap]}</span><br />
                  </p>
                  <Rating percentage={rate} />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-2 card-bottom align-middle">
              <button className="offers-button" onClick={
                () => offertButtonEvent(buy_sell + id + '/' + seller + '/' + swBuyOrSell)
              }>
                {isLoading === false ? option :
                  <div className="sparklife-loading">
                    <Puff
                      height="30"
                      width="30"
                      radisu={1}
                      color="#fff"
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
